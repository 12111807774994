import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as System from "@harborschool/lighthouse"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { useTheme } from "@harborschool/lighthouse"
import { modalState, userState } from "../store"
import DecoLine from "../components/deco-line"
import { MODAL } from "../constants"
import { sizeBg, guideBg } from "../utils"
import { CourseCards } from "../components/course-cards"
import { useIntl } from "../../plugins/gatsby-plugin-intl-with-root"

const CoursesPage = () => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const setModal = useSetRecoilState(modalState)
  const user = useRecoilValue(userState)
  const intl = useIntl()

  return (
    <System.Block
      overrides={{
        Block: {
          padding: `0 ${theme.sizing.scale3200}`,
          [theme.mediaQuery.large]: {
            padding: 0,
          },
        },
      }}
    >
      <System.Spacing height="scale1600" backgroundColor={sizeBg} />
      <System.Block
        maxWidth={`${theme.breakpoints.large}px`}
        margin="0 auto"
        backgroundColor={guideBg}
        overrides={{
          Block: {
            [theme.mediaQuery.large]: {
              padding: `0 ${theme.sizing.scale800}`,
            },
          },
        }}
      >
        <System.DisplayXSmall>Courses</System.DisplayXSmall>
        <System.Spacing height="scale700" backgroundColor={sizeBg} />
        {!user.member && (
          <System.Block font="font700" color="contentPrimary">
            <System.StyledLink onClick={() => setModal(MODAL.BECOME_A_MEMBER)}>
              <System.Block font="font700" color={theme.colors.primary}>
                Become a member
              </System.Block>
            </System.StyledLink>{" "}
            to get accesses of all contents
          </System.Block>
        )}
        {user.member && (
          <System.Block font="font700" color="contentPrimary">
            {intl.formatMessage({ id: "ui.cta.you_are_a_member" })}
          </System.Block>
        )}

        <System.Spacing height="scale1200" backgroundColor={sizeBg} />
        <DecoLine />

        <System.Spacing height="scale1200" backgroundColor={sizeBg} />
        <System.Block
          display="grid"
          gridGap={theme.sizing.scale900}
          overrides={{
            Block: {
              gridTemplateColumns: "repeat(3, 1fr)",
              [theme.mediaQuery.large]: {
                gridTemplateColumns: "1fr",
              },
            },
          }}
        >
          <CourseCards />
        </System.Block>
      </System.Block>
      <System.Spacing height="scale1600" backgroundColor={sizeBg} />
    </System.Block>
  )
}

export default () => (
  <Layout>
    <SEO title="Courses" />
    <CoursesPage />
  </Layout>
)
