import React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"

const DecoLine = props => {
  const theme = useTheme()

  return (
    <System.Block
      width="100px"
      height="2px"
      backgroundColor={theme.colors.backgroundTertiary}
    />
  )
}

DecoLine.defaultProps = {}

export default DecoLine
